import React from 'react';

const VirtualTour = ({ url }) => (
  <div id="virtualTour" className="container sm-w85">
    <iframe
      src={url}
      frameBorder="0"
    ></iframe>
  </div>
);

export default VirtualTour;