import React, { Component } from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import Helmet from 'react-helmet';

import AboutRoom from './../components/about-room';
import BookingForm from './../components/booking-form';
import Footer from './../components/footer';
import HeroSlider from './../components/hero-slider';
import NavSection from './../components/nav-section';
import Layout from './../components/layout';
import RedroomEventsSection from './../components/redroom-events';
import VirtualTour from './../components/virtual-tour';
import { FIRST_FLOOR_AREA, VENUES } from '../config/constants';

export class ReceivingArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      moveMsg: '',
    };
  }

  load() {
    this.setState({ msg: 'Interact with image !' });
  }
  render() {
    const navMenus = get(this, 'props.data.allContentfulNavigation.edges');
    const {
      title,
      header,
      aboutThisRoom,
      floor,
      size,
      capacity,
      floorPlan,
      slideImages,
      virtualTourLink,
      imageMap,
      imageMapWidth

    } = get(this, 'props.data.allContentfulVenues.edges')[0].node;
    
    const footer = get(this, 'props.data.allContentfulFooter.edges')

    const newSlideImages = [];
    slideImages.map(img => {
      newSlideImages.push({url: img.file.url})
    })

    const bookingForm = get(this, 'props.data.allContentfulBookingForm.edges')[0].node;
    
    return (
      <Layout>
        <div className="sm-transNav">
          <NavSection navMenus={navMenus} navTheme="nav-transparent" />
        </div>
        <Helmet title={title} />
        <HeroSlider slides={newSlideImages} />
        <AboutRoom
          title={title}
          floor={floor}
          size={size}
          capacity={capacity}
          floorPlan={floorPlan.file.url}
          imageMap={JSON.parse(imageMap.internal.content)}
          imageMapWidth={imageMapWidth}
          load={() => this.load()}
        >
          <p
            dangerouslySetInnerHTML={{
              __html: aboutThisRoom.aboutThisRoom
            }}
          ></p>
        </AboutRoom>
        {/* <VirtualTour url={virtualTourLink}  /> */}
        <VirtualTour url="https://exsight360.com/virtual-tours/palacio-de-memoria/v8/tour.html" />
        <RedroomEventsSection room={title} />
        <BookingForm bookingForm={bookingForm}/>
        <Footer 
          footer={footer}
        />
      </Layout>
    );
  }
}

export default ReceivingArea;

export const pageQuery = graphql`
  query ReceivingAreaQuery($slug: String) {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulVenues(filter: {slug: {eq: $slug}}) {
      edges {
        node {
          title
          slug
          header
          aboutThisRoom {
            aboutThisRoom
          }
          capacity
          floor
          floorPlan {
            file {
              url
            }
          }
          size
          slideImages {
            file {
              url
            }
          }
          virtualTourLink
          link
          linkText
          imageMap {
            internal {
              content
            }
          }
          imageMapWidth
        }
      }
      }
    allContentfulNavigation(sort: { fields: createdAt }) {
      edges {
        node {
          id
          slug
          pageTitle
          submenu
        }
      }
    }
    allContentfulBookingForm {
      edges {
        node {
          header
          firstNameInputPlaceholder
          lastNameInputPlaceholder
          contactNoInputPlaceholder
          emailInputPlaceholder
          bookingTypeInputPlaceholder {
            name
            value
          }
          dateInputPlaceholder
          timeInputPlaceholder
          venueInputPlaceholder {
            name
            value
            selected_value
          }
          messageInputPlaceholder
          newsletterInputPlaceholder
          submitInputPlaceholder
        }
      }
    }
    allContentfulFooter {
      edges {
        node {
          facebookLink
          footerAddress
          footerEmail
          footerHeadline
          footerImage {
            file {
              url
            }
          }
          footerLandline
          footerMobileNumber
          footerOpeningDates
          footerOpeningHours
          instagramLink
          section
        }
      }
    }
  }
`;
