import React from 'react';

export default class HeroSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      slides: this.props.slides,
      currentIndex: 0,
    };
  }

  handlePrev = e => {
    const { currentIndex, slides } = this.state;
    if (currentIndex == 0) {
      this.setState({ currentIndex: slides.length - 1 });
    } else {
      this.setState({ currentIndex: currentIndex - 1 });
    }
  };

  handleNext = e => {
    const { currentIndex, slides } = this.state;
    if (currentIndex < slides.length - 1) {
      this.setState({ currentIndex: currentIndex + 1 });
    } else {
      this.setState({ currentIndex: 0 });
    }
  };

  renderSlides = () => {
    const { slides, currentIndex } = this.state;
    const HeroSlider = slides.map((slide, i) => {
      const slideImage = slide.url;
      const isActive = currentIndex === i;
      return (
        isActive && (
          <div
            key={i}
            className="hero-section special-slider"
            style={{ backgroundImage: `url(${slideImage})` }}
          ></div>
        )
      );
    });
    return HeroSlider;
  };
  render() {
    return (
      <section>
        {this.renderSlides()}
        <div className="slide-arrow-indicators">
          <button onClick={e => this.handlePrev(e)}>
            <img src="/images/white-arrow-left.png" alt="" />
          </button>
          <button onClick={e => this.handleNext(e)}>
            <img src="/images/white-arrow-right.png" alt="" />
          </button>
        </div>
      </section>
    );
  }
}
