import React from 'react';
import ImageMapper from 'react-image-mapper';

const AboutRoom = ({ title, children, floor, size, capacity, floorPlan, imageMap, imageMapWidth, header = 'About this room', bookingCta, load}) => {
  const url = typeof window !== 'undefined' ? window.location.href : '';

  return(
  <section className="about-room">
    <div className="container">
      <h1>{title}</h1>
      <div className="row justify-content-between">
        <div className="col-lg-5">
          <h3 className="font-gtaM">{header}</h3>
          <p>{children}</p>
          <p className="mb40">
            <b>FLOOR</b> {floor} <br />
            <b>SIZE</b> {size} <br />
            <b>CAPACITY</b> {capacity} <br />
          </p>
          {bookingCta &&
          <a href='#bookingForm'>
            <button className="btn">BOOK NOW</button>
          </a>
          }
          {url.includes('loggia') &&
          <a href="/venues/the-loggia-restaurant/" style={{marginLeft: '10px' }}>
            <button className="btn">View Restaurant</button>
          </a>
          }
        </div>
        <div className="col-lg-6">
          <h3 className="font-gtaM">Floor Plan</h3>
          {/* <div className="image-container">
            <img src={floorPlan} alt="" className="mt-3" />
          </div> */}
           <div className="map">
              <ImageMapper
                src={floorPlan}
                map={imageMap}
                width={imageMapWidth}
                onLoad={() => load}
                />
            </div>
        </div>
      </div>
    </div>
  </section>
);
}

export default AboutRoom;
