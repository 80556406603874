import React from 'react';
import { Link } from 'gatsby';

class RedroomEventsSection extends React.Component {
  render() {
    const { room } = this.props;
    return (
      <div className="container pt-5 pb-4 bordered-top take-a-pick-section">
        <div className="row">
          <div className="col-12 text-center">
            <h1 className="red mb30">Host your event at {room}</h1>
            <p>Come together and create new memories in this historic venue.</p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4 text-center mt-5">
            <img src="/images/wedding-event.jpg" alt="" />
            <h3 className="red">Wedding Reception</h3>
            <div className="row action-btns mt30 mb-3 pb-3 justify-content-center">
              <Link to="/events/weddings">
                <button className="btn">LEARN MORE</button>
              </Link>
            </div>
          </div>
          <div className="col-md-4 text-center mt-5">
            <img src="/images/private-event.jpg" alt="" />
            <h3 className="red">Cocktails and Events</h3>
            <div className="row action-btns mt30 mb-3 pb-3 justify-content-center">
              <Link to="/events/cocktails-and-events">
                <button className="btn">LEARN MORE</button>
              </Link>
            </div>
          </div>
          <div className="col-md-4 text-center mt-5">
            <img src="/images/photoshoot-event.jpg" alt="" />
            <h3 className="red">Photoshoot</h3>
            <div className="row action-btns mt30 mb-3 pb-3 justify-content-center">
              <Link to="/events/photo-and-video-shoots">
                <button className="btn">LEARN MORE</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RedroomEventsSection;
